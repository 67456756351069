import * as Yup from 'yup';
import { AuthUser } from '#/types/auth';
import { ILivingWillForm } from '#/types/livingWill';

export const schemaValues = (
  user: AuthUser,
  dbFormValues?: ILivingWillForm,
  activeStep?: number
) => ({
  first_name: user?.first_name,
  last_name: user?.last_name,
  date_of_birth: user?.date_of_birth || null,
  civil_status: user?.civil_status || '',
  street: user?.address?.street || '',
  house_number: user?.address?.house_number || '',
  zip_code: user?.address?.zip_code || '',
  city: user?.address?.city || '',
  country: user?.address?.country || 'CH',
  primary_representative: Number(dbFormValues?.primary_representative) || '',
  secondary_representative:
    Number(dbFormValues?.secondary_representative) || '',
  repInformed: false,
  representative_inform_date: dbFormValues?.representative_inform_date || '',
  reason_for_directive: dbFormValues?.reason_for_directive || '',
  pain_management_preference: dbFormValues?.pain_management_preference || '',
  forego_measures: dbFormValues?.forego_measures || '',
  revive_preference: dbFormValues?.revive_preference || '',
  treatment_goal_sudden_incapacity:
    dbFormValues?.treatment_goal_sudden_incapacity || '',
  suffering_relief_sudden_incapacity:
    dbFormValues?.suffering_relief_sudden_incapacity || '',
  treatment_goal_prolonged_incapacity:
    dbFormValues?.treatment_goal_prolonged_incapacity || '',
  suffering_relief_prolonged_incapacity:
    dbFormValues?.suffering_relief_prolonged_incapacity || '',
  treatment_goal_permanent_incapacity:
    dbFormValues?.treatment_goal_permanent_incapacity || '',
  suffering_relief_permanent_incapacity:
    dbFormValues?.suffering_relief_permanent_incapacity || '',
  step: activeStep,
});

export const stepOneSchema = Yup.object().shape({
  date_of_birth: Yup.date()
    .required('validations.user.date_of_birth.required')
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
      'validations.user.date_of_birth.max'
    ),
  civil_status: Yup.string().required('validations.user.civil_status'),
  street: Yup.string().required('validations.adress.street'),
  house_number: Yup.string().required('validations.adress.house_number'),
  zip_code: Yup.string().required('validations.adress.zip_code'),
  city: Yup.string().required('validations.adress.city'),
  country: Yup.string().required('validations.adress.country'),
});

export const stepTwoSchema = Yup.object().shape({
  primary_representative: Yup.string().required(
    'validations.primary_representative'
  ),
  secondary_representative: Yup.string().required(
    'validations.secondary_representative'
  ),
  representative_inform_date: Yup.string().when('repInformed', {
    is: true,
    then: (schema) => schema.required('validations.rep_inform_date'),
  }),
});

export const stepThreeSchema = Yup.object().shape({
  reason_for_directive: Yup.string().required('validations.selectAnOption'),
  pain_management_preference: Yup.string().required(
    'validations.selectAnOption'
  ),
  forego_measures: Yup.string().required('validations.selectAnOption'),
  revive_preference: Yup.string().required('validations.selectAnOption'),
});

export const stepFourSchema = Yup.object().shape({
  treatment_goal_sudden_incapacity: Yup.string().required(
    'validations.selectAnOption'
  ),
  suffering_relief_sudden_incapacity: Yup.string().required(
    'validations.selectAnOption'
  ),
  treatment_goal_prolonged_incapacity: Yup.string().required(
    'validations.selectAnOption'
  ),
  suffering_relief_prolonged_incapacity: Yup.string().required(
    'validations.selectAnOption'
  ),
  treatment_goal_permanent_incapacity: Yup.string().required(
    'validations.selectAnOption'
  ),
  suffering_relief_permanent_incapacity: Yup.string().required(
    'validations.selectAnOption'
  ),
});

export const allValuesReset = {
  primary_representative: null,
  secondary_representative: null,
  repInformed: false,
  representative_inform_date: null,
  reason_for_directive: null,
  pain_management_preference: null,
  forego_measures: null,
  revive_preference: null,
  treatment_goal_sudden_incapacity: null,
  suffering_relief_sudden_incapacity: null,
  treatment_goal_prolonged_incapacity: null,
  suffering_relief_prolonged_incapacity: null,
  treatment_goal_permanent_incapacity: null,
  suffering_relief_permanent_incapacity: null,
};
