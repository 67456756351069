import LivingForm from '../../components/pages/LivingWill/LivingForm';
import Page from '#/components/shared/ui/Page';

export default function LivingWill() {
  return (
    <Page
      title="Patientenverfügung erstellen"
      description="Die Patientenverfügung regelt, welchen Behandlungen Sie zustimmen oder ablehnen, falls Sie nicht mehr in der Lage sind, Entscheidungen für sich zu treffen."
    >
      <LivingForm />
    </Page>
  );
}
