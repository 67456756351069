/* eslint-disable import/no-cycle */
import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useEffect, useRef } from 'react';
import { treatmentsQuestions } from '../stepsData';
import {
  StyledFormControlLabel,
  StyledRadio,
  StyledRadioGroup,
} from '../../../../PensionProvision/styles';
import { ILivingWillForm } from '#/types/livingWill';
import useLocales from '../../../../../../lib/hooks/useLocales';

const Treatments = () => {
  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  const refs = useRef<{ [key: string]: HTMLElement | null }>({});

  const { translate } = useLocales();

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      const errorKeys = Object.keys(errors);

      const errorElements = errorKeys
        .map((key) => refs.current[key])
        .filter((ref) => ref !== undefined && ref !== null);

      if (errorElements.length > 0) {
        const highestErrorElement = errorElements.reduce((highest, current) => {
          const currentTop = current ? current.getBoundingClientRect().top : 0;
          const highestTop = highest ? highest.getBoundingClientRect().top : 0;

          return currentTop < highestTop ? current : highest;
        }, errorElements[0]);

        if (highestErrorElement) {
          highestErrorElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }
      }
    }
  }, [errors]);

  const handleOnChange = (
    questionName: string,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue?.(questionName as keyof ILivingWillForm, event.target.value);
  };

  const handleDefaultValues = (question: string) =>
    watch?.(`${question}` as keyof ILivingWillForm);

  const translatedQuestions = treatmentsQuestions.map((question, i) => ({
    ...question,
    question: translate(`livingWill.stepThree.questions.${i}.question`),
    answers: question.answers.map((answer, j) => ({
      ...answer,
      label: translate(
        `livingWill.stepThree.questions.${i}.options.${j}.label`
      ),
    })),
  }));

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* <ReactPlayer */}
        {/*  url="https://youtu.be/iNdK6IapPcQ" */}
        {/*  playing */}
        {/*  light="/assets/images/youtubeThumbnail.webp" */}
        {/*  width={670} */}
        {/* /> */}
      </Box>
      <Stack spacing={10}>
        {translatedQuestions.map((question, index) => (
          <div
            key={question.value}
            ref={(ref) => {
              refs.current[`${question.value}`] = ref;
            }}
          >
            <Controller
              name={question.value}
              control={control}
              defaultValue={handleDefaultValues(question.value)}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <StyledRadioGroup
                  aria-label={question.id}
                  name={question.value}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    handleOnChange(question.value, e);
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ mb: 1, mt: index === 0 ? 2 : 0 }}
                  >
                    {String(question.question)}
                  </Typography>
                  <Stack
                    direction={{
                      xs: 'column',
                      md: 'row',
                    }}
                    flexDirection="row"
                    spacing={2}
                  >
                    {question.answers.map((option) => (
                      <StyledFormControlLabel
                        key={`step-${question.id}-${option.value}`}
                        value={option.value}
                        control={<StyledRadio />}
                        label={String(option.label)}
                      />
                    ))}
                  </Stack>
                  {!!error && (
                    <FormHelperText error={!!error} sx={{ mx: 0 }}>
                      {error && String(translate(error?.message))}
                    </FormHelperText>
                  )}
                </StyledRadioGroup>
              )}
            />
          </div>
        ))}
      </Stack>
    </Box>
  );
};

export default Treatments;
