import { Box, MenuItem, Stack, Typography } from '@mui/material';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { RHFSelect } from '#/components/shared/hook-form';
import { IRepresentative } from '#/types/representative';
import Iconify from '#/components/shared/ui/Iconify';
import useLocales from '#/hooks/useLocales';

type PriorityCheckProps = {
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
  representatives: IRepresentative[] | undefined;
  addNew: () => void;
  handleAddFor: (rep: string) => void;
};

const PriorityCheck = ({
  representatives,
  watch,
  setValue,
  addNew,
  handleAddFor,
}: PriorityCheckProps) => {
  const { translate } = useLocales();

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          md: '50%',
        },
        gap: 3,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography variant="subtitle1">
        {String(translate('global.priority'))}
      </Typography>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={1} alignItems="center">
          <RHFSelect
            name="primary_representative"
            label={String(translate('global.formLabels.primaryRepresentative'))}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: (theme) => theme.palette.primary.main,
                },
              },
            }}
          >
            <MenuItem
              onClick={() => {
                handleAddFor('primary_representative');
                addNew();
              }}
            >
              <Iconify icon="mdi:account-plus" color="text.secondary" />
              <Typography
                variant="caption"
                sx={{
                  textTransform: 'none',
                }}
              >
                {String(translate('global.addAnother'))}
              </Typography>
            </MenuItem>
            {representatives?.map((representative) => (
              <MenuItem
                disabled={
                  watch('secondary_representative') === representative.id
                }
                key={representative.id}
                value={representative.id}
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography>
                    {representative.first_name} {representative.last_name}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </RHFSelect>
          {watch('primary_representative') && (
            <Iconify
              icon="tabler:playstation-x"
              onClick={() => setValue('primary_representative', null)}
              sx={{
                cursor: 'pointer',
              }}
            />
          )}
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <RHFSelect
            name="secondary_representative"
            label={String(
              translate('global.formLabels.secondaryRepresentative')
            )}
            sx={{
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'green',
                },
              },
              '& .MuiOutlinedInput-root.Mui-focused': {
                '& fieldset': {
                  borderColor: 'green',
                },
              },
              '& .MuiInputLabel-outlined.Mui-focused': {
                color: 'green',
              },
            }}
          >
            <MenuItem
              onClick={() => {
                handleAddFor('secondary_representative');
                addNew();
              }}
            >
              <Iconify icon="mdi:account-plus" color="text.secondary" />
              <Typography
                variant="caption"
                sx={{
                  textTransform: 'none',
                }}
              >
                {String(translate('global.addAnother'))}
              </Typography>
            </MenuItem>
            {representatives?.map((representative) => (
              <MenuItem
                disabled={watch('primary_representative') === representative.id}
                key={representative.id}
                value={representative.id}
              >
                {representative.first_name} {representative.last_name}
              </MenuItem>
            ))}
          </RHFSelect>
          {watch('secondary_representative') && (
            <Iconify
              icon="tabler:playstation-x"
              onClick={() => setValue('secondary_representative', null)}
              sx={{
                cursor: 'pointer',
              }}
            />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default PriorityCheck;
