/* eslint-disable import/no-cycle */
import { Dispatch, SetStateAction } from 'react';
import About from './About';
import Representative from './Representatives/Representative';
import Treatments from './Treatments/Treatments';
import YourLivingWill from './YourLivingWill/YourLivingWill';
import { ILivingWillForm } from '#/types/livingWill';

export const stepsData = [
  {
    label: 'Basics',
    title: 'What is it about?',
    content: () => <About />,
  },
  {
    label: '',
    title: 'The people who take care',
    content: () => <Representative />,
  },
  {
    label: 'Treatments',
    title: 'When should it take effect?',
    content: () => <Treatments />,
  },
  // {
  //   label: '',
  //   title: "Let's clear the details",
  //   content: () => <MoreDetails />,
  // },

  {
    label: 'Your living will',
    title: 'Now what?',
    content: (
      setActiveStep?: Dispatch<SetStateAction<number>>,
      outputValues?: ILivingWillForm
    ) => <YourLivingWill outputValues={outputValues} />,
  },
];

export const treatmentsQuestions = [
  {
    id: '1',
    value: 'reason_for_directive',
    answers: [
      {
        value: 'takePrecautions',
      },
      {
        value: 'makeProvisions',
      },
      {
        value: 'makeProvisionsForFuture',
      },
    ],
  },
  {
    id: '2',
    value: 'pain_management_preference',
    answers: [
      {
        value: 'exhaustedMedicalPossibilities',
      },
      {
        value: 'alleviateSuffering',
      },
    ],
  },
  {
    id: '3',
    value: 'forego_measures',
    answers: [
      {
        value: 'yes',
      },
      {
        value: 'no',
      },
    ],
  },
  {
    id: '4',
    value: 'revive_preference',
    answers: [
      {
        value: 'yes',
      },
      {
        value: 'no',
      },
    ],
  },
];

export const detailsQuestions = [
  {
    id: '5',
    value: 'treatment_goal_sudden_incapacity',
    answers: [
      {
        value: 'resuscitationAndTreatment',
      },
      {
        value: 'treatedInIntensiveCareUnit',
      },
    ],
  },
  {
    id: '6',
    value: 'suffering_relief_sudden_incapacity',
    answers: [
      {
        value: 'medicationsAndTreatmentTherapies',
      },
      {
        value: 'noMedicationsAndTreatmentTherapies',
      },
    ],
  },
  {
    id: '7',
    value: 'treatment_goal_prolonged_incapacity',
    answers: [
      {
        value: 'resuscitationAndTreatment',
      },
      {
        value: 'treatedInIntensiveCareUnit',
      },
    ],
  },
  {
    id: '8',
    value: 'suffering_relief_prolonged_incapacity',
    answers: [
      {
        value: 'medicationsAndTreatmentTherapies',
      },
      {
        value: 'noMedicationsAndTreatmentTherapies',
      },
    ],
  },
  {
    id: '9',
    value: 'treatment_goal_permanent_incapacity',
    answers: [
      {
        value: 'resuscitationAndTreatment',
      },
      {
        value: 'treatedInIntensiveCareUnit',
      },
    ],
  },
  {
    id: '10',
    value: 'suffering_relief_permanent_incapacity',
    answers: [
      {
        value: 'medicationsAndTreatmentTherapies',
      },
      {
        value: 'noMedicationsAndTreatmentTherapies',
      },
    ],
  },
];
