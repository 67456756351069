import { Box, MenuItem, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import React from 'react';
import ReactPlayer from 'react-player/youtube';
import {
  RHFTextField,
  RHFSelect,
  RHFDatePicker,
} from '#/components/shared/hook-form';
import useLocales from '../../../../../lib/hooks/useLocales';
import AutoAddressPicker from '#/components/shared/hook-form/AutoAddressPicker';
import useAuth from '#/hooks/useAuth';
import WarningStatusModal from '#/components/pages/User/WarningStatusModal';
import useCheckCivilStatus from '#/hooks/useCheckCivilStatusChange';

const countries = [{ code: 'CH', label: 'global.switzerland' }];

const civilStatus = [
  {
    code: 'single',
  },
  {
    code: 'married',
  },
  {
    code: 'divorced',
  },
  {
    code: 'widowed',
  },
  {
    code: 'registered_partnership',
  },
];

const About = () => {
  const [openWarning, setOpenWarning] = React.useState(false);

  const handleOpenWarning = () => setOpenWarning(true);
  const handleCloseWarning = () => setOpenWarning(false);

  const { user } = useAuth();
  const { translate } = useLocales();

  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const fieldNames = {
    street: 'route',
    house_number: 'street_number',
    zip_code: 'postal_code',
    city: 'locality',
  };

  const isStreetError = Boolean(errors?.street);

  useCheckCivilStatus({
    user,
    watch,
    handleOpenWarning,
  });

  const handleChangeAnswer = () => {
    setValue('civil_status', user?.civil_status);
    handleCloseWarning();
  };

  return (
    <Box sx={{ width: '100%' }}>
      {/* <Typography variant="body1"> */}
      {/*  {String(translate('livingWill.stepOne.introParagraph'))} */}
      {/* </Typography> */}
      {/* <Typography variant="body1" sx={{ my: 2 }}> */}
      {/*  {String(translate('livingWill.stepOne.helperParagraph'))} */}
      {/* </Typography> */}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ReactPlayer
          url="https://youtu.be/1HSURogKSYw"
          playing
          light="https://storage.gutgeregelt.ch/assets/main-landings/sections/youtubeThumbnail.webp"
          width={670}
        />
      </Box>
      <Stack
        spacing={2}
        sx={{
          marginTop: '2rem',
        }}
      >
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          sx={{
            width: '100%',
          }}
          spacing={2}
        >
          <Stack
            width={{
              xs: '100%',
              md: '50%',
            }}
          >
            <RHFDatePicker
              name="date_of_birth"
              label={String(translate('global.formLabels.date_of_birth'))}
              disableFuture
            />
          </Stack>
          <Stack
            width={{
              xs: '100%',
              md: '50%',
            }}
          >
            <RHFSelect
              name="civil_status"
              label={String(translate('global.formLabels.civilStatus'))}
            >
              {civilStatus.map((status) => (
                <MenuItem key={status.code} value={status.code}>
                  {String(translate(`global.civilStatus.${status.code}`))}
                </MenuItem>
              ))}
            </RHFSelect>
          </Stack>
        </Stack>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          spacing={2}
        >
          <Stack
            sx={{
              width: '100%',
            }}
          >
            <AutoAddressPicker
              fieldNames={fieldNames}
              streetError={isStreetError}
              name="street"
            />
          </Stack>
          <RHFTextField
            name="house_number"
            label={String(translate('global.formLabels.houseNumber'))}
          />
        </Stack>
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          spacing={2}
        >
          <RHFTextField
            name="zip_code"
            label={String(translate('global.formLabels.postalCode'))}
          />
          <RHFTextField
            name="city"
            label={String(translate('global.formLabels.city'))}
          />
        </Stack>
        <RHFSelect
          name="country"
          label={String(translate('global.formLabels.country'))}
          value={countries.find((country) => country.code === 'CH')?.code}
        >
          {countries.map((country) => (
            <MenuItem key={country.code} value={country.code}>
              {String(translate(country.label))}
            </MenuItem>
          ))}
        </RHFSelect>
      </Stack>
      <WarningStatusModal
        open={openWarning}
        closeDialog={handleCloseWarning}
        changeAnswer={handleChangeAnswer}
      />
    </Box>
  );
};

export default About;
