import { Document, Page, View, Text } from '@react-pdf/renderer';
import useLocales from '../../../../../../lib/hooks/useLocales';
import styles from './styles';
import { ILivingWillForm } from '#/types/livingWill';
import { fDate } from '#/utils/formatTime';

type LivingWillDocProps = {
  outputValues?: ILivingWillForm | any;
};
const LivingWillDoc = ({ outputValues }: LivingWillDocProps) => {
  const { translate } = useLocales();

  const address = `${outputValues?.street} ${outputValues?.house_number}, ${outputValues?.zip_code} ${outputValues?.city}, ${String(
    translate(`global.${outputValues?.country?.toLowerCase()}`)
  )}`;

  return (
    <Document
      pageMode="fullScreen"
      title={String(translate('global.services.livingWill'))}
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.headSection}>
          <Text style={styles.title}>
            {String(translate('livingWill.stepFive.document.title'))}
          </Text>
          <Text>{String(translate('livingWill.stepFive.document.from'))}</Text>
          <View style={{ marginTop: 20 }}>
            <Text style={styles.title}>
              {`${outputValues?.first_name} ${outputValues?.last_name}`}
            </Text>
            <Text>
              {String(translate('livingWill.stepFive.document.born'))}
              {fDate(outputValues?.date_of_birth, 'dd. MMM yyyy', 'de')}
            </Text>
            <Text>
              {String(
                translate('livingWill.stepFive.document.civil_status', {
                  civil_status: translate(
                    `livingWill.stepFive.document.civilStatus.${outputValues?.civil_status}`
                  ),
                })
              )}
            </Text>
            <Text>
              {String(
                translate('livingWill.stepFive.document.address', { address })
              )}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text>
            {String(
              translate('livingWill.stepFive.document.declare', {
                first_name: outputValues?.first_name,
                last_name: outputValues?.last_name,
              })
            )}
          </Text>
          <View style={styles.pointParagraphs}>
            <Text>1.</Text>
            <View style={styles.pointContent}>
              <Text>
                {String(translate('livingWill.stepFive.document.paragraph1'))}
              </Text>
              <View>
                <Text style={styles.title}>
                  {outputValues?.representative?.first_name}{' '}
                  {outputValues?.representative?.last_name}
                </Text>
                <Text>
                  {String(
                    translate('livingWill.stepFive.document.address', {
                      address: `${outputValues?.representative?.address
                        ?.street} ${outputValues?.representative?.address
                        ?.house_number}, ${outputValues?.representative?.address
                        ?.zip_code} ${outputValues?.representative?.address
                        ?.city}, ${String(
                        translate(
                          `global.${outputValues?.representative?.address?.country?.toLowerCase()}`
                        )
                      )}`,
                    })
                  )}
                </Text>
              </View>
              <Text>
                {String(translate('livingWill.stepFive.document.paragraph2'))}
              </Text>
              <Text>
                {String(translate('livingWill.stepFive.document.paragraph3'))}
              </Text>
              {outputValues?.representative_inform_date && (
                <Text>
                  {String(
                    translate(
                      'livingWill.stepFive.document.repInformParagraph',
                      {
                        representative_inform_date: fDate(
                          outputValues?.representative_inform_date
                        ),
                      }
                    )
                  )}
                </Text>
              )}
            </View>
          </View>
          <View style={styles.pointParagraphs}>
            <Text>2.</Text>
            <View style={styles.pointContent}>
              <Text>
                {String(
                  translate(
                    'livingWill.stepFive.document.secondaryRepParagraph'
                  )
                )}
              </Text>
              <View>
                <Text style={styles.title}>
                  {outputValues?.secondary_representative?.first_name}{' '}
                  {outputValues?.secondary_representative?.last_name}
                </Text>
                <Text>
                  {String(
                    translate('livingWill.stepFive.document.address', {
                      address: `${outputValues?.secondary_representative
                        ?.address?.street} ${outputValues
                        ?.secondary_representative?.address
                        ?.house_number}, ${outputValues
                        ?.secondary_representative?.address
                        ?.zip_code} ${outputValues?.secondary_representative
                        ?.address?.city}, ${String(
                        translate(
                          `global.${outputValues?.secondary_representative?.address?.country?.toLowerCase()}`
                        )
                      )}`,
                    })
                  )}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>
            {String(translate('livingWill.stepFive.document.motivation.title'))}
          </Text>
          <Text>
            {String(
              translate('livingWill.stepFive.document.motivation.paragraph1')
            ) +
              String(
                translate(
                  `livingWill.stepFive.document.motivation.${outputValues?.reason_for_directive}`
                )
              )}
          </Text>
          <Text>
            {String(
              translate('livingWill.stepFive.document.motivation.paragraph2')
            ) +
              String(
                translate(
                  `livingWill.stepFive.document.motivation.${outputValues?.pain_management_preference}`
                )
              )}
          </Text>
          <Text style={styles.title}>
            {String(translate('livingWill.stepFive.document.medical.title'))}
          </Text>
          <Text>
            {String(
              translate('livingWill.stepFive.document.medical.paragraph1')
            )}
          </Text>
          <Text>
            {String(
              translate(
                `livingWill.stepFive.document.medical.${outputValues.pain_management_preference}`
              )
            )}
          </Text>
          <Text>
            {String(
              translate(
                `livingWill.stepFive.document.forego_measures.${outputValues.forego_measures}`
              )
            )}
          </Text>
          <Text>
            {`${String(
              translate(
                'livingWill.stepFive.document.revive_preference.paragraph'
              )
            )} ${String(
              translate(
                `livingWill.stepFive.document.revive_preference.${outputValues?.revive_preference}`
              )
            )} ${String(
              translate(
                'livingWill.stepFive.document.revive_preference.paragraph1'
              )
            )}`}
          </Text>
          {outputValues?.treatment_goal_sudden_incapacity && (
            <>
              <Text>
                {String(
                  translate(
                    'livingWill.stepFive.document.moreDetails.treatment_goal_sudden_incapacity.paragraph'
                  )
                ) +
                  String(
                    translate(
                      `livingWill.stepFive.document.moreDetails.treatment_goal_sudden_incapacity.${outputValues?.treatment_goal_sudden_incapacity}`
                    )
                  )}
              </Text>
              <Text>
                {String(
                  translate(
                    'livingWill.stepFive.document.moreDetails.treatment_goal_prolonged_incapacity.paragraph'
                  )
                ) +
                  String(
                    translate(
                      `livingWill.stepFive.document.moreDetails.treatment_goal_prolonged_incapacity.${outputValues?.treatment_goal_sudden_incapacity}`
                    )
                  )}
              </Text>
              <Text>
                {String(
                  translate(
                    'livingWill.stepFive.document.moreDetails.treatment_goal_permanent_incapacity.paragraph'
                  )
                ) +
                  String(
                    translate(
                      `livingWill.stepFive.document.moreDetails.treatment_goal_permanent_incapacity.${outputValues?.treatment_goal_sudden_incapacity}`
                    )
                  )}
              </Text>
            </>
          )}
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>
            {String(translate('livingWill.stepFive.document.revocation.title'))}
          </Text>
          <Text>
            {String(
              translate('livingWill.stepFive.document.revocation.paragraph1')
            )}
          </Text>
          <Text style={styles.title}>
            {String(
              translate('livingWill.stepFive.document.abilityToConsent.title')
            )}
          </Text>
          <Text>
            {String(
              translate(
                'livingWill.stepFive.document.abilityToConsent.paragraph1'
              )
            )}
          </Text>
          {outputValues?.representative_inform_date && (
            <Text>
              {String(
                translate(
                  'livingWill.stepFive.document.abilityToConsent.informedRepresentative'
                )
              )}
            </Text>
          )}
          <View
            style={{
              marginTop: 20,
            }}
          >
            <Text>
              {outputValues?.first_name} {outputValues?.last_name}
            </Text>
          </View>
          <View
            style={{
              ...styles.pointParagraphs,
              gap: 20,
            }}
          >
            <Text>{address}</Text>
            <Text>{fDate(new Date(), 'dd. MMM yyyy', 'de')}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default LivingWillDoc;
